<template>
  <div class="bg-[#d5d9e1] border items-center flex justify-center p-20 mt-16">
    <p class="text-center">Copyright 2023 | Gökay Şen</p>
  </div>
</template>

<script>
export default {
    name:'FooterPage',
}
</script>

<style>

</style>