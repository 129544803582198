<template>
  <div>
    <Header @scrollTo="goto" />
    <PersonalInfo />
    <development-info />
    <!-- <work-experience/> -->
    <!-- <Carousel /> -->
    <HireMe id="1" />
    <!-- <CarouselBlog/> -->
    <Contact id="2" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import PersonalInfo from "@/components/PersonalInfo.vue";
import HireMe from "@/components/HireMe.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import DevelopmentInfo from "@/components/DevelopmentInfo.vue";
// import Carousel from "@/components/Carousel.vue";
// import CarouselBlog from "@/components/CarouselBlog.vue";
// import WorkExperience from '@/components/WorkExperience.vue';

export default {
  name: "PersonalPortfolio",
  components: {
    Header,
    PersonalInfo,
    HireMe,
    Contact,
    // Carousel,
    // CarouselBlog,
    Footer,
    DevelopmentInfo,
    // WorkExperience
  },
  setup() {
    const goto = (id) => {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    };
    return {
      goto,
    };
  },
};
</script>
