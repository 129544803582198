<template>
  <div class="flex items-center mt-8 justify-center">
    <div class="flex flex-row items-center justify-between space-x-10 md:w-1/2">
      <div class="flex gap-2 items-center">
      <img src="@/assets/header.svg" alt="header" />
      <h1 class="font-bold text-base">Gökay</h1>
    </div>
    <div>
      <ul class="flex flex-row gap-10">
        <!-- <li>Portfolio</li>
        <li>Blog</li> -->
        <li @click="scrollToEmitted('1')" class="cursor-pointer">Why Choose Me</li>
        <li @click="scrollToEmitted('2')" class="cursor-pointer">Contact</li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  setup (_, { emit }) {
    const scrollToEmitted = (refName) => {
      emit('scrollTo', refName)
    }
    return {
      scrollToEmitted
    }
  }
};
</script>

<style>
</style>