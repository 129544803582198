<template>
  <div class="bg-[#fff] mt-20 items-center md:px-40 p-4">
    <h1 class="font-bold text-4xl mb-10 text-center">Why Should You Choose Me?</h1>
    <div class="flex flex-col gap-4 md:flex-row">
      <div
        class="
          flex flex-col
          items-center
          justify-center
          gap-4
          border
          p-4
          rounded-md
        "
        v-for="info in informations"
        :key="info.id"
      >
        <img
          :src="require(`@/assets/${info.icon}`)"
          alt="communicative"
          class="w-12 h-12"
        />
        <h1 class="font-bold text-lg">{{ info.text }}</h1>
        <p class="max-w-md">{{ info.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HireMe",
  setup() {
    const informations = [
      {
        icon: "communicative.png",
        text: "Communicative",
        desc: "I take care to express my ideas, feelings, and knowledge to others in a clear and understandable way. I can establish a strong and positive connection.",
      },
      {
        icon: "professional.png",
        text: "Professional",
        desc: "I use the right methods, best practices and up-to-date technologies in the software development process. I aim to complete my projects on time, in accordance with ethical values and with high quality. In addition, I diligently pay attention to understanding and meeting customer needs and expectations.",
      },
      {
        icon: "collobarative.png",
        text: "Collobarative",
        desc: "I think it provides many advantages such as speeding up the project, increasing efficiency and improving quality. It combines more ideas and capabilities to meet clients' expectations and allows them to combine my own expertise and ideas.",
      },
      {
        icon: "favourite.png",
        text: "Client's Favourite",
        desc: "It is defined as a feature that customers choose and like based on factors such as the software's quality, ease of use, and suitability for their needs. As a software developer, I prioritize customer satisfaction and customer loyalty. In addition, the software that customers like can offer the most suitable and effective solutions for them and keep customer satisfaction at the highest level. Paying attention to this, I process professional software processes.",
      },
    ];
    return {
      informations,
    };
  },
};
</script>