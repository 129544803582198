<template>
  <div class="flex flex-col p-4 gap-4 justify-center md:space-x-10 mt-20 md:flex-row">
    <div class="flex flex-col gap-6">
      <div class="flex border rounded-md p-4">
        <img src="@/assets/web.svg" alt="web" />
        <div class="pl-4 items-center flex flex-col justify-center">
          <p class="font-bold text-center">Web Development</p>
        </div>
      </div>
      <div class="flex border rounded-md p-4">
        <img src="@/assets/app.svg" alt="app" />
        <div class="pl-4 items-center flex flex-col justify-center">
          <p class="font-bold text-center">Cross Platform Development</p>
        </div>
      </div>
      <div class="flex border rounded-md p-4">
        <img src="@/assets/app.svg" alt="app" />
        <div class="pl-4 items-center flex flex-col justify-center">
          <p class="font-bold text-center">Responsive Platform Development</p>
        </div>
      </div>
    </div>
    <div class="gap-4 flex flex-col justify-between">
      <h1 class="font-bold text-4xl">What do I help?</h1>
      <p class="max-w-lg leading-8 text-md font-normal">
        Create and maintain your websites and also take care of its performance
        and traffic capacity. Standard designing, building, and implementing
        your applications with documentation.
      </p>
      <div class="flex gap-4 flex-row justify-between items-center">
       <div class="flex flex-col">
        <p class="font-extrabold text-4xl">50 + </p>
      <span>Project Completed</span>
       </div>
       <div class="flex flex-col">
        <p class="font-extrabold text-4xl">40 + </p>
      <span>Happy Client</span>
       </div>
       <div class="flex flex-col">
        <p class="font-extrabold text-4xl">3 + </p>
      <span>Experience</span>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioV2",
};
</script>