<template>
  <div class="bg-[#fff] mt-12 md:px-20">
    <h1 class="font-bold text-4xl text-center">Contact</h1>
    <div class="flex flex-col justify-between items-center md:flex-row mt-4">
      <div class="flex flex-col gap-8 w-1/2">
        <p class="font-extrabold text-4xl">
          Let's make something amazing together.
        </p>
        <p class="font-extrabold text-4xl">
          Start by <span class="text-green-500">saying hi!</span>
        </p>
      </div>
      <div class="flex flex-col gap-4 mt-10 md:w-1/2">
        <form
          ref="form"
          @submit.prevent="sendEmail"
          class="flex flex-col gap-4"
        >
          <input
            type="text"
            placeholder="Name"
            name="user_name"
            class="
              border
              rounded-md
              p-2
              focus:border-green-400
              outline-none
              bg-white
            "
          />
          <input
            type="text"
            placeholder="Subject"
            name="user_subject"
            class="
              border
              rounded-md
              p-2
              focus:border-green-400
              outline-none
              bg-white
            "
          />
          <input
            type="text"
            placeholder="Email"
            name="user_email"
            class="
              border
              rounded-md
              p-2
              focus:border-green-400
              outline-none
              bg-white
            "
          />
          <textarea
            cols="30"
            rows="10"
            placeholder="Message"
            name="message"
            class="
              border
              rounded-md
              p-2
              focus:border-green-400
              outline-none
              bg-white
            "
          />
          <input
            type="submit"
            value="Send Message"
            class="bg-green-400 p-2 border rounded-md mb-6"
          />
          {{ isSendDone ? "Thank you..." : "" }}
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import emailjs from "@emailjs/browser";
export default {
  name: "ContactPage",
  setup() {
    const form = ref({
      name: "",
      subject: "",
      email: "",
      message: "",
    });
    const isSendDone = ref(false);
    const sendEmail = () => {
      emailjs
        .sendForm(
          "service_0toephp",
          "template_80k1x2a",
          form.value,
          "fpzlHR9iKXgGs6MnA"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            isSendDone.value = true;
          },
          (error) => {
            console.log("FAILED...", error.text);
            isSendDone.value = false;
          }
        );
    };

    return {
      form,
      sendEmail,
      isSendDone,
    };
  },
};
</script>

<style>
</style>