<template>
  <div
    class="
      flex flex-col-reverse
      justify-center
      items-center
      mt-20
      md:space-x-10
      p-4
      md:flex-row
    "
  >
    <div>
      <div>
        <h1 class="font-extrabold text-[#232e35] text-3xl text-start mb-5">
          Hi!
        </h1>
        <div class="typewriter">
          <h1 class="font-extrabold text-[#232e35] text-3xl text-start mb-5 md:whitespace-nowrap">I'm Gökay Şen | Front End Developer</h1>
        </div>
        <p
          class="
            text-base
            leading-8
            font-normal
            text-[#232e35] text-start
            mb-5
            max-w-md
          "
        >
          Creative front-end developer with more than 3+ years of experience in
          enterprise companies and startups. Proficient in JavaScript, Vue, and
          React, React Native.
        </p>
        <div class="flex items-center gap-4 mt-5">
          <a href="https://github.com/gokysn" target="blank">
            <img src="@/assets/Github.svg" alt="" />
          </a>
          <a href="https://www.linkedin.com/in/g%C3%B6kay-%C5%9Fen-4b484416b/" target="blank">
            <img src="@/assets/Linkedin.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div>
      <img
        class="w-80 bg-[#eae6fe] rounded-full h-80"
        src="@/assets/profile_photo.jpg"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.typewriter h1 {
  overflow: hidden;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
    border: none;
  }
  50% {
    border-color: black;
  }
}
</style>